import React from "react";
import {
  Dialog as BaseDialog,
  withStyles,
  Paper,
  makeStyles,
  Button,
} from "@material-ui/core";
import theme from "../theme";

const StyledPaper = withStyles({
  root: {
    bottom: "0px",
    position: "fixed",
    marginBottom: "unset",
    borderRadius: "16px 16px 0px 0px",
    padding: "16px",
    margin: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 444,
    width: "100%",
  },
})(Paper);

const useStyles = makeStyles({
  sliderBar: {
    width: "80px",
    height: "4px",
    backgroundColor: "#f5f5f5",
    borderRadius: "10px",
    alignSelf: "center",
    cursor: "pointer",
    marginBottom: 24,
  },
  button: {
    fontWeight: 700,
    marginTop: 38,
    padding: 8,
    color: "white",
    width: "100%",
    boxShadow: "0px 10px 15px rgba(51, 131, 225, 0.2)",
    borderRadius: "5px",
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 7,
  },
  subtitle: {
    fontSize: 12,
    color: "#808080",
    marginBottom: 37,
  },
  fullPaper: {
    marginLeft: "-16px",
    position: "absolute",
    bottom: 0,
    height: "fit-content",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    textAlign: "center",
  },
  disabledButton: {
    boxShadow: "none !important",
    backgroundColor: "#BFBFBF !important",
    color: "white !important",
  },
});

interface ReusableDialogType {
  onClose?: () => any;
  open: boolean;
  avatar?: string;
  content: any;
  button?: string;
  disabled?: boolean;
  onSubmit?: () => void;
}

const ReusableDialog = (props: ReusableDialogType) => {
  const classes = useStyles();

  const { open, onClose, avatar, content, button, disabled, onSubmit } = props;
  return (
    <BaseDialog
      onClose={onClose}
      open={open}
      disableScrollLock={true}
      PaperComponent={StyledPaper}
      aria-labelledby="draggable-dialog-title"
    >
      <div
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <div
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <div id="draggable-dialog-title" className={classes.sliderBar} />
        </div>
        {avatar && (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <img
              src={avatar}
              alt="avatar-dialog"
              style={{ width: 200, marginBottom: 38 }}
            />
          </div>
        )}
        {content}
        {button && (
          <div
            style={{
              width: "100%",
              bottom: 0,
              position: "relative",
              display: "flex",
            }}
          >
            <Button
              className={classes.button}
              style={{
                backgroundColor: theme.palette.primary.main,
              }}
              variant="contained"
              fullWidth
              onClick={onSubmit}
              disabled={disabled}
              classes={{ disabled: classes.disabledButton }}
            >
              {button}
            </Button>
          </div>
        )}
      </div>
    </BaseDialog>
  );
};

export default ReusableDialog;
